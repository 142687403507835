
.selected-categories-list {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 20px;
    margin-top: 10px;
    
    li {
        margin-bottom: 5px;
    }
}
