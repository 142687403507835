
.vg-key-figures-panel{
	height: inherit;
	width: calc(100% - 20px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	margin-right: 20px;
	.header{
		background-color: #E8EAEE;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		font-size: 16px;
		height: 40px;
		width: 100%;
		padding: 0 10px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.content{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		width: 100%;
		height: calc(100% - 40px - 20px - 10px);
		overflow-y: auto;
		>div{
			max-height: 30vh;
			min-height: 200px;
		}
	}
}
.reduced-panel{
	border-left: 1px solid #E8EAEE;
}
@media only screen and (max-width: 1200px){
	
}

