
.operation-check{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	>div:first-child{
		display:flex;
		justify-content: flex-start;
		align-items:center;
	}
	i{
		margin-right: 10px;
		cursor: pointer;
		color: #35b1ea;
	}
	>div>span{
		margin-right: 10px;
	}
	.subtitle{
		color: #BBBCBE;
	}
}
.operation-fermeture{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	.subtitle{
		color: #BBBCBE;
	}
}
.operation-cloture-file{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}
.operation-cloture-file:hover{
	cursor: pointer;
}
.operation-remplacement-equipement{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap:10px;
}
