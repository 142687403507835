
.modal-title {
  display: flex;
  align-items: center;
  gap: 12px;

  .title-icon {
    width: 24px;
    height: 24px;
  }
}

.invitation-container {
  padding: 16px;
}

.create-form {
  .form-group {
    margin-bottom: 20px;
    position: relative;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #3880ff;
    font-size: 20px;
  }

  #role-info-panel {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0;
    border-left: 4px solid #3880ff;

    h4 {
      margin-top: 0;
      color: #333;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 0;
    }

    li {
      margin-bottom: 8px;
    }
  }

  .dropdown {
    position: relative;

    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 4px;

      &::after {
        content: '▼';
        font-size: 12px;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
      display: none;

      &.show {
        display: block;
      }
    }

    .dropdown-item {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .info-box {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-top: 10px;

    p {
      margin: 0 0 10px 0;
      color: #555;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .option-label {
    display: block;
    margin-top: 15px;
    color: #666;
    font-size: 14px;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.invitation-result {
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    margin: 20px 0;
    padding: 24px;
    background-color: rgba(40, 167, 69, 0.05);
    border-radius: 8px;

    .success-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      background-color: #28a745;
      border-radius: 50%;
      box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
      animation: pulse 2s infinite;
    }

    .success-icon {
      width: 40px;
      height: 40px;
      color: white;
    }

    .success-title {
      margin: 0;
      color: #28a745;
      font-size: 24px;
      font-weight: 600;
    }

    .success-message {
      margin: 0;
      color: #6c757d;
      max-width: 400px;
      line-height: 1.5;
    }

    .expiry-notice {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 16px;
      padding: 12px 16px;
      background-color: #fff3cd;
      border-left: 4px solid #ffc107;
      border-radius: 4px;
      width: 100%;

      .calendar-icon {
        width: 20px;
        height: 20px;
        color: #856404;
      }

      span {
        color: #856404;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(40, 167, 69, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
  }
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 4px;
}

.is-invalid {
  border-color: #dc3545;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  margin-right: 8px;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
