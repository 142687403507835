
/* L'icône tourne automatiquement selon l'état du menu */
.vs__open-indicator {
  transition: transform 0.2s ease-in-out;
}

.vs__open-indicator[aria-hidden="false"] {
  transform: rotate(180deg); /* Rotation quand le menu est ouvert */
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
$default-height: 30px;
$lg-height: 40px;
    .vg-v-select{
        font-size: 16px !important;
        width: 100%;
        background: white;
        ::placeholder{
            color: #bbbcbe;
        }
        .vg-select-option{
            display: flex;
            flex-direction: row;
            margin: 7px 0;
            &.check{
                color: rgb(47, 151, 227);
                font-weight: bold;
                &:hover{
                    color:white;
                }
            }
            .name-option{
                width: 90%;
            }
        }
        .name-option-container{
            margin-right: 10px;
        }
        .vs__dropdown-toggle{
            border: 1px solid #dcdfe6;
            border-radius: 0px;
            height: $default-height !important;
        }
        .vs__dropdown-menu{
            display: block;
            z-index: 999 !important;
        }
        .vs__selected-options{
            padding: 0px !important;
            align-items: center;
        }
        .vs__selected{
            /*border: 1px solid #dcdfe6;*/
            /*background-color: #fff;*/
            padding: 0px 10px;
            margin: 0 0 0 5px !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: -webkit-fill-available;
            >div{
                height: -webkit-fill-available;
            }

            &.multiple{
                border: 1px solid #d9ecff;
                border-radius: 4px;
                background-color: #f4f4f5;
                border-color: #e9e9eb;
                color: #909399;
                font-size: 12px;
                .fa-times{
                    cursor: pointer;

                }
            }


        }
        .vs__deselect{
            margin: 0px 5px;
        }
        .vs__search{
            margin:0px !important;
            height:$default-height !important;
        }
    }
    .vg-v-select-lg{
        height: $lg-height !important;
        font-size: 16px !important;
        .vs__dropdown-toggle{
            height: $lg-height !important;
        }
    }
