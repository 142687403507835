
.vg-fin-demo{
	width: 100%;
	background-color: black !important;
	color: white !important;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	span{
		white-space: nowrap;
	}

	.info{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2px;
	}
	.btn-demander{
		padding-left: 10px !important;
		padding-right: 10px !important;
		background-color: #fd5a55;
		border: 1px solid #fd5a55;
		box-shadow: 0 1px #fd5a55;
		border-radius: 4px;
		text-transform: uppercase;
		color: white !important;
	}
}
@media only screen and (max-width: 1200px){
	.vg-fin-demo{
		display: block !important;
		span{
			white-space: normal;
		}
		.info{
			display: inline;
			margin-right: 10px;
		}
	}
}

