
.filters-group {
    padding: 1rem;
    
    &__quick-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
    }

    &__main {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;
    }

    &__item {
        transition: all 0.3s ease;

        &:hover {
            transform: translateY(-2px);
        }
    }
}

.checkbox-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.checkbox-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }
}


.selector-group {
    display: flex;
    gap: 0.5rem;
}

// Transitions
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

@media (max-width: 768px) {
    .filters-group {
        &__quick-actions {
            flex-direction: column;
        }

        &__main {
            grid-template-columns: 1fr;
        }
    }

}
