
.modal-title {
    display: flex;
    align-items: center;
    gap: 12px;
    .title-icon {
        width: 24px;
        height: 24px;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    .spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }
}

.links-list {
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.link-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    .link-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 12px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .origin-badge {
        background: #e9ecef;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
    }

    .link-body {
        margin-bottom: 16px;

        .description {
            color: #6c757d;
            font-size: 14px;
            margin: 8px 0;
        }

        .expiry {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #dc3545;
            font-size: 13px;
        }

        .shared-link-info {
            color: #6c757d;
            font-size: 14px;
            margin-top: 8px;
        }
    }

    .link-actions {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
    }
}

.create-form {
    background: white;
    border-radius: 8px;
    padding: 24px;

    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 24px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .links-grid {
        grid-template-columns: 1fr;
    }
}
