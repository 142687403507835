
.vg-button-dropdown{
    position: relative;
    .button-dropdown{
        // height: 40px;
        padding: 0px 20px !important;
        margin-right: 0 !important;
        border-radius: 3px;
        width:100%;
        &:hover{
            box-shadow: 0px 0px 5px #f99b75 !important;
        }
        &:focus{
            box-shadow: none !important;
        }
    }
    .submenu-dropdown{
        display: block;
        position: absolute;
        top: 45px;
        right: 0px;
        z-index: 500;
        background: white;
        width: max-content;

        border: 1px solid #d4d4d4;
        box-shadow: 1px 1px 3px #d3d3d3;
        animation-duration: .2s;
        animation-name: vg-slide-top;
        li{
            text-decoration: none;
            list-style: none;
            text-align: left;
            padding:5px 10px;
            font-family: "Open Sans";
            font-size: 14px;
            cursor: pointer;

            &:hover{
                background-color: #e4e4e4;
                animation-duration: .1s;
                animation-name: vg-list-color;
                @keyframes vg-list-color {
                  from {background-color: white;}
                  to {background-color: #e4e4e4;}
                }
            }
        }
    }
}


