
.btn-camera {
    float: right;
    z-index: 999 !important;
    position: absolute;
    right: 20px;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.btn-camera-high-quality {
    background-color:#50b659;
}
#scann-icon-quality{
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url("static/assets/icone/custom/focus.svg");
    mask-image: url("static/assets/icone/custom/focus.svg");
}

.scanner-container {
    height: -webkit-fill-available;
    width:-webkit-fill-available;
    #reader{
        width: -webkit-fill-available;
    }
}
.feedback-scanner{
    color: #35B1EA;
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    gap:10px;
    width:100%;
    min-height: 50%;
    height: 100%;
    font-size: 20px;
    #scann-icon-loader{
        width: 60px;
        height: 60px;
        animation: rotate 0.8s linear infinite;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/loader-circle.svg");
        mask-image: url("static/assets/icone/custom/loader-circle.svg");
        background-color: #35B1EA;
    }
    #scann-icon-valid{
        width: 60px;
        height: 60px;
        animation: draw-check 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-face.svg");
        mask-image: url("static/assets/icone/custom/scan-face.svg");
        background-color: #35B1EA;
    }
    #scann-icon-search{
        width: 60px;
        height: 60px;
        animation: pulse 1.5s infinite;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-search.svg");
        mask-image: url("static/assets/icone/custom/scan-search.svg");
        background-color: #35B1EA;
    }
    #scann-icon-error{
        width: 60px;
        height: 60px;
        animation: draw-error 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-line.svg");
        mask-image: url("static/assets/icone/custom/scan-line.svg");
        background-color: red;
    }
    #scann-icon-impossible{
        width: 60px;
        height: 60px;
        animation: draw-error 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/ban.svg");
        mask-image: url("static/assets/icone/custom/ban.svg");
        background-color: red;
    }
}
.feedback-scanner-error{
    color: red;
}
.btn-back{
    font-size: 16px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    height:30px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes draw-check {
    from {
        opacity: 0;
        transform: rotate(45deg) scale(0);
    }
    to {
        opacity: 1;
        transform: rotate(45deg) scale(1);
    }
    
}
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes draw-error {
    0% {
        opacity: 0;
        transform: rotate(0deg) scale(0);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
    50% {
        opacity: 1;
        transform: rotate(0deg) scale(2);
    }
    
}
