
.todo-list{
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
}
.todo-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
    padding:10px 5px;
    border-bottom:1px solid whitesmoke;
    width:100%;
}
.todo-row:hover{
    background-color:whitesmoke;
    cursor:pointer;
}
