
.manage-invitations-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
}

.filter-container {
  margin-bottom: 20px;
}

.filter-group {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.filter-email {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
}

.filter-role {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-label {
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}

.role-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.invitation-info-card {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  
  .card-icon {
    margin-right: 16px;
    
    img {
      width: 32px;
      height: 32px;
    }
  }
  
  .card-content {
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }
    
    p {
      margin: 0;
      color: #6c757d;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
}

.invitations-list {
  margin-top: 24px;
  
  h2 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
  }
}

.table-responsive {
  overflow-x: auto;
}

.invitations-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  th, td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
  }
  
  th {
    font-weight: 600;
    color: #495057;
    background-color: #f8f9fa;
  }
  
  tr:last-child td {
    border-bottom: none;
  }
  
  .actions-column {
    text-align: center;
    min-width: 180px;
  }
  
  .actions-cell {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}

.role-badge {
  display: inline-block;
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  
  &.pending {
    background-color: #ffc107;
    color: #212529;
  }
  
  &.accepted {
    background-color: #28a745;
    color: white;
  }
  
  &.completed {
    background-color: #6c757d;
    color: white;
  }
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #6c757d;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    
    h1 {
      margin-bottom: 16px;
    }
  }
  
  .filter-group {
    flex-direction: column;
  }
  
  .filter-email, .filter-role {
    width: 100%;
    max-width: 100%;
  }
  
  .invitations-table {
    th, td {
      padding: 8px;
    }
    
    .actions-cell {
      flex-direction: column;
    }
  }
}
