
.vg-input{
  font-size:16px !important;
}
.vg-input-area{
  font-size:16px !important;
}
.password-not-ok{
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  padding: 5px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  i{
    color: red;
    margin-right: 2px;
  }
}
.vg-input-financial{
    text-align: right;
}
.vg-input-block {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-family: "Open Sans";
}
.vg-input-not-empty-field {
  /*background-color: #f7fafc;*/
}
.vg-input {
  -webkit-appearance: none;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #40475b;
  display: inline-block;
  /*font-size: 17px;*/
  font-family: "Open Sans";
  height: 30px;
  /*line-height: 40px;*/
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  /*margin-top: 7px;*/
  &.mobile{
      /*font-size:15px;*/
  }
}
.vg-input-block ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #acabbb;
  font-size: 12px;
}

.vg-input-area {
  -webkit-appearance: none;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #40475b;
  display: inline-block;
  /*font-size: 18px;*/
  font-family: "Open Sans";
  line-height: 30px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  /*margin-top: 7px;*/
}
.vg-input:focus {
  border: 1px solid #2a9bd4;
}
.vg-input-area:focus {
  border: 1px solid #2a9bd4;
}
.vg-input-block {
  .input-label{
    color: #9e9da9;
    font-family: "Open Sans";
    font-size: 12px;
    margin-bottom: 10px;
    width: max-content;
    display: inline-block;
  }
}

.vg-input-important{
    border-color: red;
}

input{
	/* Firefox */
	-moz-appearance: textfield;

	/* Chrome */
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin:0;
	}

	/* Opéra*/
	&::-o-inner-spin-button {
		-o-appearance: none;
		margin: 0;
	}
}

/* inline */
.vg-input-inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .vg-input{
        width: inherit;
    }
    .vg-input-block{
        margin-bottom:0px !important;
        margin-right:15px;
    }
}
.vg-input-inline {
    .input-label{
      margin-bottom: 0px;
      white-space: nowrap;
      margin-right: 40px;
    }
}

