
.footer-mode-edition{
    width:100%;
    height:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 20px;
    gap:20px;
    .formulaire-creation-pieces-buttons{
        flex-grow:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        gap:10px;
    }
}
.select-etage{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 24px;
    color: #02a7f0;
}
@media screen and (max-width: 1200px) {
    .select-etage{
        height:50px;
        font-size: 18px;
    }
}
.selection-etage-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
    width: 400px !important;
    padding: 20px 10px;
    position: absolute;
    right: 0;
    z-index:200;
    height: -webkit-fill-available;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
}
.selection-etage-panel.show {
    display: block !important;
    transform: translateX(0);
}
