
.lancement-container{
    padding:24px; 
    height:--webkit-fill-available;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    .title{
        font-size: 24px;
    }
    .subtitle{
        font-size: 16px;
        color: #4B5563;
    }
    .separation-line{
        border-color: rgb(243 244 246/var(1));
        border-width: 1px;
    }
}
.button-lancement-scann{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 24px;
    border-radius: 12px;
    background-color: #2563eb;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}
.button-lancement-scann:hover {
    background-color: #1d4ed8;
}
.button-lancement-scann span {
    padding-top:3px;
    font-weight: 500;
}

